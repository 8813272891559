import {
  Container,
  Side,
  Report,
  Cards,
  Card,
  Graphs,
  Index,
  Graph,
  Table,
} from "./styles";


export default function SPEDs() {
  return (
    <Container>
  
    </Container>
  );
}
