import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import axios from "axios";
import { useAuth } from "./auth";

const ReportContext = createContext();

export const ReportsProvider = ({ children }) => {
  const [documents, setDocuments] = useState([]);
  const [infos, setInfos] = useState({});
  const { projeto, token } = useAuth();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [documentType, setDocumentType] = useState("all");
  const [documentStatus, setDocumentStatus] = useState("all");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const PAGE_SIZE = 100; // Defina o tamanho da página aqui

  const fetchDocuments = useCallback(
    async (currentPage = 1, term = searchTerm) => {
      setLoading(true);
      try {
        const filters = {};
        if (documentType !== "all") {
          filters.type = documentType === "nfe" ? "55" : "57";
        }
        if (documentStatus !== "all") {
          filters.status =
            documentStatus === "auth" ? "Autorizada" : "Cancelada";
        }
        if (term) {
          filters.nfe = term;
        }
        const response = await axios.get(`https://dev.vidal-app.com/report`, {
          params: {
            page: currentPage,
            pageSize: PAGE_SIZE,
            filters: JSON.stringify(filters),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
        });
        const newDocuments = response.data;
        console.log(
          `Fetched ${newDocuments.length} documents for page ${currentPage}`
        );

        if (newDocuments.length === 0) {
          setHasMore(false);
          setDocuments([]);
        } else {
          setDocuments((prevDocuments) => {
            if (currentPage === 1) {
              return newDocuments;
            } else {
              return [...prevDocuments, ...newDocuments];
            }
          });
          setHasMore(newDocuments.length === PAGE_SIZE);
        }
      } catch (error) {
        console.error("Erro ao buscar os Reports:", error);
        setHasMore(false);
      } finally {
        setLoading(false);
      }
    },
    [token, projeto._id, documentType, documentStatus, searchTerm]
  );

  const getInfos = useCallback(async () => {
    try {
      const filters = {};
      if (documentType !== "all") {
        filters.type = documentType === "nfe" ? "55" : "57";
      }
      if (documentStatus !== "all") {
        filters.status = documentStatus === "auth" ? "Autorizada" : "Cancelada";
      }
      if (searchTerm) {
        filters.nfe = searchTerm;
      }
      const response = await axios.get(
        `https://dev.vidal-app.com/report/infos`,
        {
          params: {
            filters: JSON.stringify(filters),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
        }
      );
      setInfos(response.data);
    } catch (error) {
      console.error("Erro ao buscar as Infos:", error);
    }
  }, [token, projeto._id, documentType, documentStatus, searchTerm]);

  const handleSearch = useCallback(
    (term) => {
      setSearchTerm(term);
      setPage(1);
      setHasMore(true);
      setDocuments([]);
      fetchDocuments(1, term);
    },
    [fetchDocuments]
  );

  useEffect(() => {
    if (token && projeto._id) {
      fetchDocuments(1);
      getInfos();
    }
  }, [token, projeto._id, fetchDocuments, getInfos]);

  const handleDocumentTypeChange = useCallback(
    (type) => {
      if (documentType !== type) {
        setDocumentType(type);
        setPage(1);
        setHasMore(true);
        setDocuments([]);
      }
    },
    [documentType]
  );

  const handleDocumentStatusChange = useCallback(
    (status) => {
      if (documentStatus !== status) {
        setDocumentStatus(status);
        setPage(1);
        setHasMore(true);
        setDocuments([]);
      }
    },
    [documentStatus]
  );

  const [mensal, setMensal] = useState(null);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth());

  const fetchData = useCallback(
    async (ano, mes) => {
      try {
        const response = await axios.get(
          `https://dev.vidal-app.com/report/date`,
          {
            params: { ano, mes },
            headers: {
              Authorization: `Bearer ${token}`,
              projeto: projeto._id,
            },
          }
        );
        setMensal(response.data);
      } catch (error) {
        setMensal(null);
        console.error("Error fetching data:", error);
      }
    },
    [token, projeto._id, projeto]
  );

  useEffect(() => {
    fetchData();
  }, [projeto]);

  const handleMonthChange = useCallback(
    (direction) => {
      setMes((prevMes) => {
        let newMes = prevMes;
        let newAno = ano;

        if (direction === "up") {
          newMes = prevMes === 11 ? 0 : prevMes + 1;
          newAno = prevMes === 11 ? ano + 1 : ano;
        } else {
          newMes = prevMes === 0 ? 11 : prevMes - 1;
          newAno = prevMes === 0 ? ano - 1 : ano;
        }

        fetchData(newAno, newMes);
        setAno(newAno);
        return newMes;
      });
    },
    [ano, fetchData]
  );

  useEffect(() => {
    if (mensal === null && token && projeto._id) {
      fetchData(ano, mes);
    }
  }, [mensal, ano, mes, fetchData, token, projeto._id]);

  return (
    <ReportContext.Provider
      value={{
        documents,
        infos,
        getInfos,
        fetchDocuments,
        setPage,
        searchTerm,
        handleSearch,
        hasMore,
        page,
        documentType,
        documentStatus,
        handleDocumentTypeChange,
        handleDocumentStatusChange,
        loading,
        mensal,
        ano,
        mes,
        handleMonthChange,
        fetchData,
        setMensal,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReports = () => {
  return useContext(ReportContext);
};
