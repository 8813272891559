// providers.js
import React from "react";
import TemaProvider from "@hooks/theme";
import DashProvider from "@hooks/dash";
import LocalStorageProvider from "@hooks/useLocalStorage";
import { AuthProvider } from "@hooks/auth";
import { ReportsProvider } from "@hooks/report";
import { DropdownProvider } from "@hooks/dropDown"; // Import this
import { ProjectsProvider } from "./hooks/projects";

const providers = [
  TemaProvider,
  DashProvider,
  AuthProvider,
  ReportsProvider,
  DropdownProvider, // Add this
  ProjectsProvider,
];

const Providers = ({ children }) => {
  return (
    <LocalStorageProvider>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </LocalStorageProvider>
  );
};

export default Providers;
