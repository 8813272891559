import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  SendButton,
  UploadIcon,
  MessageWrapper,
  Message,
  SubMessage,
  DropzoneContainer,
  FileList,
  FileItem,
  DeleteButton,
} from "./styles";
import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios"; // Para fazer o POST
import { Notify } from "notiflix"; // Biblioteca para notificações
import useWebSocket from "react-use-websocket"; // Notificação de upload e atualizar interface
import { FiTrash } from "react-icons/fi";
import { PiFileTxtDuotone } from "react-icons/pi";
//import DownloadExecution from '../DownloadExecution'; // Importe o componente DownloadExecution
import { useAuth } from "@hooks/auth";

const ModalDownload = ({ isOpen, onClose, children }) => {
  const { projeto, setProjeto, user, token } = useAuth(); // signOut para logout
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // Estado para mostrar carregamento
  const [progressMessage, setProgressMessage] = useState(""); // Estado para exibir progresso
  const [fileInfo, setFileInfo] = useState({}); // Defina a variável fileInfo
  const [numNotasFiscais, setNumNotasFiscais] = useState(0); // Armazenar o número de notas fiscais válidas no arquivo

  const { sendMessage, lastMessage } = useWebSocket(
    "wss://log.vidal-app.com/ws",
    {
      shouldReconnect: () => true,
    }
  );

  const handleWebSocketMessage = useCallback((message) => {
    const data = JSON.parse(message.data);
    if (data.type === "trackProgress") {
      setProgressMessage(
        `${data.data.stage} - Progresso: ${data.data.progress}%`
      );
    } else if (data.type === "error") {
      setProgressMessage(`Erro: ${data.data.message}`);
    }
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      handleWebSocketMessage(lastMessage);
    }
  }, [lastMessage, handleWebSocketMessage]);

  useEffect(() => {
    if (!isOpen) {
      // Resetar os estados quando o modal for fechado
      setUploadedFiles([]);
      setProgressMessage("");
    }
  }, [isOpen]);

  const onDrop = (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => {
      const fileURL = URL.createObjectURL(file);
      return {
        file, // Keep the original File object
        preview: fileURL,
        sizeInKB: (file.size / 1024).toFixed(2),
      };
    });
    setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
    setFileInfo(filesWithPreview[0]); // Update the fileInfo state
  };

  //Passar o estado fileInfo para a propriedade fileInfo do componente DownloadExecution
  //<DownloadExecution fileInfo={fileInfo} />

  //Alterado file.name para file.path
  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((fileData) => fileData.file.name !== fileName)
    );
  };

  const StyledDropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: { "text/plain": [] },
      onDrop,
    });

    return (
      <DropzoneContainer
        fullSize={uploadedFiles.length === 0}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <UploadIcon>+</UploadIcon>
        <MessageWrapper>
          <Message>Carregar arquivos</Message>
          <SubMessage>Arraste os arquivos SPED em .txt aqui</SubMessage>
        </MessageWrapper>
      </DropzoneContainer>
    );
  };

  const handleSend = async () => {
    setLoading(true);
    const formData = new FormData();
    uploadedFiles.forEach((fileData) => {
      formData.append("files", fileData.file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/tools/download-xml`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
        }
      );
      const numNotasFiscais = response.data.numNotasFiscais;
      setNumNotasFiscais(numNotasFiscais);
      Notify.success("Arquivos enviados com sucesso.");
      setProgressMessage(
        "Arquivos enviados com sucesso. Iniciando processamento..."
      );

      // Enviar mensagem WebSocket para iniciar o rastreamento do progresso
      sendMessage(
        JSON.stringify({
          type: "startProcessing",
          projectId: projeto._id,
          data: {
            script: "download_xmls",
            files: uploadedFiles.map((file) => file.file.name),
          },
        })
      );
    } catch (error) {
      Notify.failure("Erro no envio dos arquivos.");
      setProgressMessage("Erro no envio.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Clean up the object URLs when the component unmounts
    return () => {
      uploadedFiles.forEach((fileData) =>
        URL.revokeObjectURL(fileData.preview)
      );
    };
  }, [uploadedFiles]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <StyledDropzone />
        {uploadedFiles.map((fileData) => (
          <FileItem
            key={fileData.file.name}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PiFileTxtDuotone
              style={{ width: "30px", height: "17px", marginRight: "10px" }}
            />
            <MessageWrapper style={{ flexGrow: 1 }}>
              <span>{fileData.file.name}</span>
              <SubMessage>
                <span>{fileData.sizeInKB} KB</span>
              </SubMessage>
            </MessageWrapper>
            <DeleteButton onClick={() => removeFile(fileData.file.name)}>
              <FiTrash
                style={{
                  width: "30px",
                  height: "17px",
                  marginRight: "7px",
                  fill: "#0D0D11",
                }}
              />
            </DeleteButton>
          </FileItem>
        ))}

        {children}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          {uploadedFiles.length > 0 && (
            <SendButton onClick={handleSend} disabled={loading}>
              {loading ? "Enviando..." : "Enviar"}
            </SendButton>
          )}
          <CloseButton onClick={onClose}>Fechar</CloseButton>
        </div>

        {progressMessage && (
          <p style={{ color: "#67686A", marginTop: "10px" }}>
            {progressMessage}
          </p>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalDownload;
