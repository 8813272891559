import styled from "styled-components";
 
export const Display = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    background-color: ${({ theme }) => theme.custom};
    padding-left: 1em;

    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .add-btn {
      cursor: pointer;
      margin-right: 0.5em;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 0.8em;
        color: ${({ theme }) => theme.primary};
      }

      &:hover {
        opacity: 0.8;
      }
    }

    p {
      font-size: 0.8em;
      opacity: 0.7;
    }

    svg {
      opacity: 0.8;
      font-size: 0.9em;
      margin-right: 1em;
    }
  }


`;



// New styled component for UserCard
export const UserCardContainer = styled.div`
  width: 100%;
  max-height: max-content;
  align-items: center; /* Adjust as needed */
  display: flex;
  flex-direction: column;

  .user {
    width: 96%;
    height: 4em;
    display: flex;
    background-color: ${({ theme }) => theme.custom};
    border-radius: 5px;
    border:1px solid ${({ theme }) => theme.border4};
    margin-left: 5px;
    margin-top: 1em;
    transform: translateY(-0.5em);
    padding: 10px 0;

    .thumb {
      width: 4em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border3};

      .circle {
        width: 60%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.border3};

        p {
          color: ${({ theme }) => theme.text};
        }
      }
    }

    .info {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;
      font-size: .9em;

      .info-content {
        width: calc(100% - 3em);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin-left: 15px;
          color: ${({ theme }) => theme.text};
          font-size: 0.9em;
          opacity: 0.6;
          line-height: 18px;
          &.email {
            font-size: .8em;
            font-weight: 100;
          }

          &:last-child {
            font-size: 0.7em;
            opacity: 0.4;
          }
        }
      }

      .delete {
        width: 3em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          cursor: pointer;

          svg {
            font-size: 0.7em;
            opacity: 0.6;
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }
`;


