import React, { useEffect } from "react";
import axios from "axios"; // Para enviar a requisição DELETE
import { useAuth } from "@hooks/auth";
import { ModalOverlay, Container, Header, Content } from "./styles";

export default function ConfirmModal({ setOpenConfirmModal, user }) {
  const { token, projeto } = useAuth();

  const deleteUser = async () => {
    try {
      const response = await axios.delete(
        `https://dev.vidal-app.com/users/remove-user/${user._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            userId: user._id,
          },
        }
      );

      setOpenConfirmModal(false);
      window.location.reload();
    } catch (error) {
      console.error("Erro ao deletar o usuário:", error);
    }
  };

  useEffect(() => {
    console.log("user", user);
  }, []);

  return (
    <ModalOverlay onClick={() => setOpenConfirmModal(false)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <div className="user">
            <div className="thumb">
              <div
                className="circle"
                style={{ backgroundColor: projeto.color }}
              >
                <p>{user.name.charAt(0).toUpperCase()}</p>
              </div>
            </div>
            <div className="info">
              <div className="info-content">
                <p>{user.name}</p>
                <p>{user.email}</p>
              </div>
            </div>
          </div>
        </Header>
        <Content>
          <div className="info">
            <p>Tem certeza que deseja excluir este usuário?</p>
          </div>
          <div className="buttons">
            <div onClick={() => setOpenConfirmModal(false)}>
              <p>Cancelar</p>
            </div>
            <div onClick={deleteUser}>
              <p>Deletar</p>
            </div>
          </div>
        </Content>
      </Container>
    </ModalOverlay>
  );
}
