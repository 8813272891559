import React, { createContext, useContext, useEffect } from "react";
import useLocalStorageState from "@hooks/useLocalStorage.js";
import axios from "axios";

// Crie o contexto
const AuthContext = createContext();

// Crie o provider
export function AuthProvider({ children }) {
  const [status, setStatus] = useLocalStorageState(
    "auth",
    "auth_status",
    "login"
  );
  const [token, setToken] = useLocalStorageState("auth", "auth_token", "");
  const [user, setUser] = useLocalStorageState("auth", "auth_user", "");
  const [projeto, setProjeto] = useLocalStorageState("projeto", {});
  const [projetos, setProjetos] = useLocalStorageState("projetos", {});

  // Função para obter o projeto do usuário
  const getProjeto = async () => {
    try {
      const response = await axios.get(
        `https://dev.vidal-app.com/projetos/user`,
        {
          headers: {
            "user-id": user.id, // Supondo que user tem uma propriedade id
          },
        }
      );
      console.log("Projeto data: ", response.data); // Log para depuração
      setProjetos(response.data);
    } catch (error) {
      console.error("Erro ao buscar o projeto:", error);
    }
  };

  useEffect(() => {
    if (user._id && !projeto._id) {
      getProjeto();
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        status,
        setStatus,
        token,
        setToken,
        user,
        setUser,
        projeto,
        setProjeto,
        getProjeto,
        projetos,
        setProjetos,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Crie um hook para usar o contexto
export function useAuth() {
  return useContext(AuthContext);
}
