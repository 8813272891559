import React, { useContext, useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";

import {
  IoSearchOutline,
  IoDocumentOutline,
  IoCodeSlashOutline,
  IoCubeOutline,
  IoMoonOutline,
} from "react-icons/io5";

import { CiDatabase } from "react-icons/ci";
import { FaSun, FaRegUser } from "react-icons/fa";
import {
  MdOutlineKeyboardArrowDown,
  MdMemory,
  MdMailOutline,
} from "react-icons/md";
import {
  Bar,
  ModalWrapper,
  ModalContent,
  LogoutButton,
  CloseButton,
  InfoContainer,
  IconWrapper,
  InfoUser,
} from "./styles"; // Adicionei estilos para o modal
import logo from "@imgs/logo.svg";
import { useTema } from "@hooks/theme";
import { useAuth } from "@hooks/auth";
import { useProjects } from "@hooks/projects";
import { useDropdown } from "@hooks/dropDown";
import { useReports } from "@hooks/report";
import ProjectDropdown from "./components/dropDown/index";
import { LocalStorageContext } from "@hooks/useLocalStorage";

import axios from "axios";
import { toast } from "react-toastify"; // Notificação

export default function Header() {
  const { theme, toggleTheme } = useTema();
  const { projeto, setProjeto, user, token } = useAuth(); // signOut para logout
  const { isOpen, toggle, position, triggerRef } = useDropdown();
  const { setMensal } = useReports();
  const { projectInfo, fetchUserProjects, setProjectInfo } = useProjects();

  const context = useContext(LocalStorageContext);
  const [userProjects, setUserProjects] = useState([]);
  const [showModal, setShowModal] = useState(false); // Controle de exibição do modal

  const [dropdownPosition] = useState({
    top: 65,
    left: 210,
  });

  const userInitial = useMemo(() => {
    return user && user.name ? user.name.charAt(0).toUpperCase() : "";
  }, [user]);

  const handleCreateNewProject = () => {
    console.log("Criar novo projeto");
    toggle();
  };

  const handleDropdownToggle = () => {
    if (!isOpen) {
      fetchUserProjects(); // Carregar projetos do usuário ao abrir dropdown
    }
    toggle();
    console.log("projetos no header", userProjects);
  };

  // Função que abre/fecha o modal de informações do usuário
  const toggleUserModal = () => {
    setShowModal(!showModal);
  };

  // Função que redireciona o usuário para a tela de login (logout)
  const handleLogout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("projeto");
    localStorage.removeItem("projetos");
    localStorage.removeItem("report");
    localStorage.removeItem("dash_stages"); // Função de logout que redireciona para login
    // Exibir uma notificação de sucesso
    toast.success("Você foi desconectado com sucesso.");

    // Recarregar a página para aplicar as alterações
    window.location.reload(); // Recarrega a página após a mensagem de sucesso
  };

  function getLocalStorageSize() {
    let total = 0;
    for (let x in localStorage) {
      if (localStorage.hasOwnProperty(x)) {
        total += (localStorage[x].length + x.length) * 2;
      }
    }
    const units = ["B", "KB", "MB", "GB", "TB"];
    let index = 0;
    while (total >= 1024 && index < units.length - 1) {
      total /= 1024;
      index++;
    }
    return `${total.toFixed(2)} ${units[index]}`;
  }

  function formatNumber(num) {
    return num !== undefined
      ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : "0";
  }

  function formatSize(size) {
    if (size >= 1024) {
      return `${(size / 1024).toFixed(2)} GB`;
    }
    return size !== undefined ? `${size.toFixed(2)} MB` : "0 MB";
  }

  const handleProjectSelect = (selectedProject) => {
    setProjeto(selectedProject);
    setMensal();
  };

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/projetos/infos`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              projeto: projeto._id,
            },
          }
        );
        setProjectInfo(response.data);
        console.log("resposta para projectInfos", response.data);
      } catch (error) {
        setProjectInfo(null);
        console.error("Erro ao buscar informações do projeto:", error);
        return null; // Retorna null caso haja um erro
      }
    };

    fetchInfos();
  }, [projeto]);

  return (
    <Bar>
      <div className="side">
        <div className="theme" onClick={toggleTheme}>
          <FaSun className={theme === "light" ? "active" : ""} />
          <div className={`toggle ${theme}`}>
            <div className="circle"></div>
          </div>
          <IoMoonOutline className={theme === "dark" ? "active" : ""} />
        </div>
        <img src={logo} alt="Logo" />
        <div className="projeto">
          <div className="thumb">
            <IoCubeOutline style={{ color: projeto.color }} />
          </div>
          <div className="text">
            <h2>{projeto?.nome || "Selecione um projeto"}</h2>
            <p>{projeto?.cnpj || ""}</p>
          </div>
          <div className="btn" onClick={handleDropdownToggle} ref={triggerRef}>
            <MdOutlineKeyboardArrowDown />
          </div>
          {isOpen &&
            ReactDOM.createPortal(
              <ProjectDropdown
                triggerRef={triggerRef}
                onCreateProject={handleCreateNewProject}
                projects={userProjects}
                initialPosition={dropdownPosition}
                useCustomPosition={true}
                setProjectInfo={setProjectInfo}
                onSelectProject={handleProjectSelect}
              />,
              document.body
            )}
        </div>
      </div>

      <div className="side">
        <div className="infos">
          <div className="info">
            <MdMemory />
            <p>{getLocalStorageSize()}</p>
          </div>
          <div className="info">
            <IoDocumentOutline />
            <p>
              {projectInfo?.spedFilesCount !== undefined
                ? formatNumber(projectInfo.spedFilesCount)
                : "0"}
            </p>
          </div>
          <div className="info">
            <IoCodeSlashOutline />
            <p>
              {projectInfo?.xmlFilesCount !== undefined
                ? formatNumber(projectInfo.xmlFilesCount)
                : "0"}
            </p>
          </div>
          <div className="info">
            <CiDatabase />
            <p>
              {projectInfo?.projectSizeMB !== undefined
                ? formatSize(projectInfo.projectSizeMB)
                : "0 MB"}
            </p>
          </div>
        </div>


        {/* Ícone de perfil, agora com funcionalidade de modal */}
        <div className="perfil" onClick={toggleUserModal}>
          <div className="photo">{userInitial}</div>
        </div>

        {/* Renderização do modal de informações do usuário */}
        {showModal &&
          ReactDOM.createPortal(
            <ModalWrapper onClick={toggleUserModal}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                <InfoContainer>
                  <div className="user">
                    <IconWrapper>
                      <FaRegUser />
                    </IconWrapper>
                  </div>
                  <InfoUser>
                    <h3>{user.name}</h3>
                  </InfoUser>
                </InfoContainer>

                <InfoContainer>
                  <div className="mail">
                    <IconWrapper>
                      <MdMailOutline />
                    </IconWrapper>
                  </div>
                  <InfoUser>
                    <p>{user.email}</p>
                  </InfoUser>
                </InfoContainer>

                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                <CloseButton onClick={toggleUserModal}>Fechar</CloseButton>
              </ModalContent>
            </ModalWrapper>,
            document.body
          )}
      </div>
    </Bar>
  );
}
