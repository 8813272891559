import React, { useEffect, useState } from "react";
import { UserCardContainer, Display } from "./styles"; // Import the new styled component
import { FiUser } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import CreateUser from "./components/createUser";
import { useAuth } from "@hooks/auth";
import ConfirmModal from "./components/confirmModal";
import axios from "axios";

export default function UserCard() {
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const { token, projeto, user } = useAuth();

  const handleDelete = (user) => {
    setSelectedUser(user);
    setOpenConfirmModal(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `https://dev.vidal-app.com/users/projeto`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              projeto: projeto._id,
              user: user._id,
            },
          }
        );

        setUsers(response.data);
        setIsAdmin(true);
      } catch (error) {
        setIsAdmin(false);
        console.error("Erro ao buscar usuários:", error);
      }
    };

    fetchUsers();
  }, [token, projeto._id, user._id]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Display>
        <div className="header">
          {" "}
          <div className="side">
            <FiUser />
            <p>Usuários</p>
          </div>
          <div className="add-btn" onClick={() => setOpenModal(true)}>
            <FaPlus />
          </div>
        </div>
        <div className="display">
          <UserCardContainer>
            {users
              .filter((user) => user.cargo !== "god")
              .map((user) => (
                <div className="user" key={user.id}>
                  <div className="thumb">
                    <div
                      className="circle"
                      style={{ backgroundColor: user.color }}
                    >
                      <p>{user.name.charAt(0).toUpperCase()}</p>
                    </div>
                  </div>
                  <div className="info">
                    <div className="info-content">
                      <p>{user.name}</p>
                      <p className="email">{user.email}</p>
                      <p>{user.cargo}</p>
                    </div>
                    <div className="delete">
                      <div className="icon" onClick={() => handleDelete(user)}>
                        <FaRegTrashAlt />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </UserCardContainer>
        </div>
      </Display>
      {openModal && (
        <CreateUser setOpenModal={setOpenModal} selectedUser={selectedUser} />
      )}

      {openConfirmModal && (
        <ConfirmModal
          setOpenConfirmModal={setOpenConfirmModal}
          user={selectedUser}
        />
      )}
    </>
  );
}
