// components/worker/index.js

import React, { useState, useEffect } from "react";
import {
  WorkerContainer,
  Card,
  Task,
  Infos,
  Header,
  Items,
  Item,
  ProgressBarContainer,
  ProgressBar,
} from "./styles";
import { FaRobot } from "react-icons/fa"; // Corrigido de 'fa6' para 'fa'
import { MdOutlineBugReport } from "react-icons/md";
import DropBox from "../dropbox"; // Verifique se o caminho está correto
import { useAuth } from "@hooks/auth";

const Worker = ({ workerId }) => {
  const [workerData, setWorkerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { projeto } = useAuth();

  useEffect(() => {
    const ws = new WebSocket("wss://log.vidal-app.com/ws");

    ws.onopen = () => {
      const requestMessage = {
        action: "getMessages",
        type: "worker",
        projectId: projeto._id,
        page: 1,
        limit: 1,
      };
      ws.send(JSON.stringify(requestMessage));
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.success && (data.messages?.length > 0 || data.message)) {
          setWorkerData(data.messages?.[0] || data.message);
        }
      } catch (error) {
        console.error("Erro ao processar a mensagem recebida:", error);
      } finally {
        setLoading(false);
      }
    };

    ws.onerror = () => setLoading(false);
    ws.onclose = () => console.log("Conexão fechada");

    return () => ws.close();
  }, [workerId, projeto._id]);

  if (loading) return <div>Carregando...</div>;
  if (!workerData) return <div>Nenhum dado disponível</div>;

  const { status, progress, details } = workerData;

  const downloadRate = details.downloadRate || 0;
  const formattedDownloadRate =
    Math.round(downloadRate).toLocaleString("pt-BR");

  const remainingFiles = details.totalFiles - details.downloaded;
  const hoursToComplete = downloadRate > 0 ? remainingFiles / downloadRate : 0;

  let totalSeconds = hoursToComplete * 3600;
  const days = Math.floor(totalSeconds / (3600 * 24));
  totalSeconds %= 3600 * 24;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);

  let estimatedCompletionStr = "";
  if (days > 0) estimatedCompletionStr += `${days} dias `;
  if (hours > 0) estimatedCompletionStr += `${hours} horas `;
  if (minutes > 0) estimatedCompletionStr += `${minutes} minutos`;
  estimatedCompletionStr =
    estimatedCompletionStr.trim() || "Menos de um minuto";

  const percentage =
    progress.total > 0 ? (progress.current / progress.total) * 100 : 0;

  return (
    <WorkerContainer>
      <DropBox />
      <Header>
        <div className="side">
          <FaRobot />
          <p>{`WORKER ${workerId}`}</p>
        </div>
      </Header>
      <Card>
        <Task>
          <p className="task">{status === 0 ? "Baixando NFe's" : "Parado"}</p>
          <p>
            {Number(progress.current).toLocaleString("pt-BR")} /{" "}
            {Number(progress.total).toLocaleString("pt-BR")}
          </p>
        </Task>
        <ProgressBarContainer>
          <ProgressBar width={percentage} />
        </ProgressBarContainer>
        <Infos>
          <Header>
            <div className="side">
              <MdOutlineBugReport />
              <p>Informações</p>
            </div>
          </Header>
          <Items>
            <Item>
              <p>
                Arquivos Totais:{" "}
                {Number(details.totalFiles).toLocaleString("pt-BR")}
              </p>
            </Item>
            <Item>
              <p>
                Baixados: {Number(details.downloaded).toLocaleString("pt-BR")}
              </p>
            </Item>
            <Item>
              <p>Taxa de Download: {formattedDownloadRate} / h</p>
            </Item>
            <Item>
              <p>Previsão de Conclusão: {estimatedCompletionStr}</p>
            </Item>
          </Items>
        </Infos>
      </Card>
    </WorkerContainer>
  );
};

export default Worker;
