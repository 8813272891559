// index.js

import React, { useState } from "react";
import Worker from "./components/worker";
import List from "./components/list";
import DropBox from "./components/dropbox";
import { Header, AddButton, Display } from "./styles";
import { FaList, FaPlus } from "react-icons/fa"; // Importação correta

const WorkerCard = ({ workerId }) => {
  const [listData, setListData] = useState([
    { name: "COMERCIAL 09/04 - 23/11", downloaded: 500, total: 1000 },
    { name: "FINANCEIRO 01/03 - 10/09", downloaded: 300, total: 600 },
  ]);

  const handleAddList = () => {
    const dropbox = document.getElementById("dropbox");
    if (dropbox) {
      dropbox.style.display = "flex";
    }
  };

  return (
    <Display>
      <Worker workerId={workerId} />
      <Header>
        <div className="side">
          <FaList />
          <p>Listas</p>
        </div>
        <AddButton onClick={handleAddList}>
          <FaPlus />
        </AddButton>
      </Header>
      <List lists={listData} />
      <DropBox />
    </Display>
  );
};

export default WorkerCard;
