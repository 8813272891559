// components/list/index.js

import React from "react";
import {
  ListContainer,
  Card,
  Header,
  Task,
  ProgressBarContainer,
  ProgressBar,
} from "./styles";
import { IoCubeOutline } from "react-icons/io5";

const List = ({ lists }) => {
  return (
    <ListContainer>
      {lists.map((list, index) => {
        const percentage =
          list.total > 0 ? (list.downloaded / list.total) * 100 : 0;

        return (
          <Card key={index}>
            <Header>
              <div className="side">
                <IoCubeOutline />
                <p>{list.name}</p>
              </div>
            </Header>
            <Task>
              <p>Arquivos baixados:</p>
              <p>
                {Number(list.downloaded).toLocaleString()} /{" "}
                {Number(list.total).toLocaleString()}
              </p>
            </Task>
            <ProgressBarContainer>
              <ProgressBar width={percentage} />
            </ProgressBarContainer>
          </Card>
        );
      })}
    </ListContainer>
  );
};

export default List;
