import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3em;


`;

export const Menu = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  padding-bottom: 2px;
  position: fixed;
  margin-top: 3em;

  .btn {
    width: max-content;
    height: 100%;

    height: 2em;

    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.03);
    opacity: 0.6;
    border-right: 1px solid rgba(255, 255, 255, 0.02);
    padding-right: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 15px;
    padding-bottom: 3px;
    padding-top: 3px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.custom2};

    border-bottom: 4px solid rgb(130, 185, 42, 0.8);

    &.speds {
      border-bottom: 4px solid rgb(0, 100, 200, 0.8);
    }

    &.upload {
      border-bottom: 4px solid rgb(200, 0, 100, 0.8);
    }

    &:hover,
    &.ativ {
      opacity: 0.9;
    }
    p {
      font-size: 0.8em;
      font-weight: 500;
    }

    svg {
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.4em;
      padding-right: 10px;
    }
  }
`;
