// styles.js

import styled from "styled-components";

export const Display = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  background-color: ${({ theme }) => theme.custom};
  padding-left: 1em;

  .side {
    display: flex;
    align-items: center;

    p {
      font-size: 0.8em;
      opacity: 0.7;
      margin-left: 0.5em;
    }
  }

  svg {
    opacity: 0.8;
    font-size: 0.9em;
    margin-right: 1em;
  }
`;

export const AddButton = styled.div`
  cursor: pointer;
  margin-right: 0.5em;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 0.8em;
    color: ${({ theme }) => theme.primary};
  }

  &:hover {
    opacity: 0.8;
  }
`;
