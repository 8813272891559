import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;

  svg {
    fill: ${({ theme }) => theme.text};
  }	
`;

export const Side = styled.div`
  width: calc(50% - 1em);

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5em;

  

  &.left {
    min-width: 65%;
  }
`;

export const Menu = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  background-color: rgba(13, 13, 17);
  .btn {
    width: max-content;
    height: 100%;
    height: 2em;
    border-bottom: 4px solid rgb(130, 185, 42, 0.5);
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.03);
    opacity: 0.6;
    border-right: 1px solid ${({ theme }) => theme.contrast};
    padding-right: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-bottom: 3px;
    padding-top: 3px;
    cursor: pointer;

    &:hover,
    &.ativ {
      opacity: 0.9;
    }
    p {
      font-size: 0.75em;
    }

    svg {
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.4em;
      padding-right: 10px;
    }
  }
`;
