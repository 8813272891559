import styled from "styled-components";

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  width: 93%;
  margin-top: 10px;
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border3};
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.custom};
  border-bottom: 1px solid ${({ theme }) => theme.border5};

  .side {
    display: flex;
    align-items: center;

    p {
      margin-left: 10px;
      font-size: 0.85em;
      font-weight: bold;
    }

    svg {
      font-size: 1em;
    }
  }
`;

export const Task = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-family: "Roboto", sans-serif;
    font-size: 0.85em;
    font-weight: 300;
    opacity: 0.8;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.4em;
  background-color: ${({ theme }) => theme.border5};
  margin-top: 10px;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.width || 0}%;
  height: 100%;
  background-color: rgba(0, 150, 50);
`;
