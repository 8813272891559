import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "@hooks/auth";

const ProjectsContext = createContext();

export function ProjectsProvider({ children }) {
  const [userProjects, setUserProjects] = useState([]);
  const [editInfos, setEditInfos] = useState(null); // Informações do projeto sendo editado
  const [editProjectId, setEditProjectId] = useState(null); // ID do projeto a ser editado
  const [projectInfo, setProjectInfo] = useState({
    spedFilesCount: 0,
    xmlFilesCount: 0,
    projectSizeMB: 0,
  });

  const { user, token } = useAuth();

  const fetchUserProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/projetos/user`,
        {
          headers: {
            "user-id": user._id,
          },
        }
      );
      setUserProjects(response.data);
      console.log("projetos", response.data);
    } catch (error) {
      console.error("Erro ao buscar projetos do usuário:", error);
    }
  };

  /* useEffect(() => {
    const fetchProjectInfo = async () => {
      if (!editProjectId) return;

      try {
        console.log("Buscando informações do projeto com ID:", editProjectId);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/projetos/infos`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              projeto: editProjectId,
            },
          }
        );
        setEditInfos(response.data); // Armazena as informações do projeto sendo editado
      } catch (error) {
        console.error("Erro ao buscar informações do projeto:", error);
      }
    };

    fetchProjectInfo();
  }, [editProjectId, token]); */

  return (
    <ProjectsContext.Provider
      value={{
        fetchUserProjects,
        userProjects,
        setUserProjects,
        setEditProjectId, // Define o ID do projeto a ser editado
        setEditInfos,
        editInfos, // Informações do projeto sendo editado
        projectInfo,
        setProjectInfo,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
}

export function useProjects() {
  return useContext(ProjectsContext);
}
