import {
  Bar,
  Card,
  Cards,
  Container,
  Menu,
  Relatorio,
  ReportDiv,
} from "./styles";
import {
  IoSearchOutline,
  IoCodeSlashOutline,
  IoCheckmark,
} from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { useEffect, useRef, useCallback, useState, React } from "react";
import { useReports } from "@hooks/report";
import { useProjects } from "@hooks/projects";
import Mensal from "./components/mensal";
import ContentLoader from "react-content-loader";

export default function NFes() {
  const {
    documents,
    fetchDocuments,
    setPage,
    hasMore,
    page,
    infos,
    getInfos,
    documentType,
    documentStatus,
    handleDocumentTypeChange,
    handleDocumentStatusChange,
    loading,
    handleSearch,
  } = useReports();

  const { projectInfo } = useProjects();

  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const searchTimeoutRef = useRef(null);
  const observer = useRef();
  const scrollContainerRef = useRef(null); // Ref para o container de scroll
  const scrollPositionRef = useRef(0); // Ref para salvar a posição do scroll

  const lastDocumentElementRef = useCallback(
    (node) => {
      if (loading || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // Salva a posição do scroll antes de carregar mais documentos
          scrollPositionRef.current = scrollContainerRef.current.scrollTop;
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, setPage]
  );

  useEffect(() => {
    if (page > 1) {
      fetchDocuments(page);
    }
  }, [page, fetchDocuments]);

  useEffect(() => {
    getInfos();
    fetchDocuments(1);
  }, [getInfos, fetchDocuments]);

  useEffect(() => {
    // Restaura a posição do scroll após carregar novos documentos
    if (!loading && page > 1) {
      scrollContainerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [documents, loading]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      handleSearch(value);
    }, 500);
  };

  const handleSearchSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    handleSearch(localSearchTerm);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchSubmit(e);
    }
  };

  const DocumentLoader = () => (
    <ContentLoader
      speed={2}
      width={1500}
      height={50}
      viewBox="0 0 1500 50"
      backgroundColor="#616161"
      foregroundColor="#ecebeb"
    >
      <circle cx="25" cy="25" r="5" />
      <rect x="70" y="20" rx="3" ry="3" width="130" height="6" />
      <rect x="250" y="20" rx="3" ry="3" width="400" height="6" />
      <rect x="730" y="20" rx="3" ry="3" width="100" height="6" />
      <rect x="860" y="20" rx="3" ry="3" width="200" height="6" />
    </ContentLoader>
  );

 
  return (
    <Container>
      <ReportDiv>
        {" "}
        <Cards>
          {infos !== undefined &&
            Object.keys(infos).map((key, index) => (
              <Card key={index}>
                <div className="header">
                  <p>{key}</p>
                </div>
                <div className="content">{infos[key].toLocaleString()}</div>
              </Card>
            ))}
        </Cards>
        <Relatorio>
          <div className="block">
            <div className="head">
              <div className="side">
                <div className="btns">
                  <div
                    className={`btn ${documentStatus === "all" && "ativ"}`}
                    onClick={() => handleDocumentStatusChange("all")}
                  >
                    <p>Todas</p>
                  </div>
                  <div
                    className={`btn auth ${
                      documentStatus === "auth" && "ativ"
                    }`}
                    onClick={() => handleDocumentStatusChange("auth")}
                  >
                    <p>Autorizadas</p>
                  </div>
                  <div
                    className={`btn cancel ${
                      documentStatus === "cancel" && "ativ"
                    }`}
                    onClick={() => handleDocumentStatusChange("cancel")}
                  >
                    <p>Canceladas</p>
                  </div>
                </div>
              </div>
              <div className="side">
                <div className="options">
                  <div className="btns">
                    <div
                      className={`btn all ${
                        documentType === "all" ? "active" : ""
                      }`}
                      onClick={() => handleDocumentTypeChange("all")}
                    >
                      <p>Todas</p>
                    </div>
                    <div
                      className={`btn nfe ${
                        documentType === "nfe" ? "active" : ""
                      }`}
                      onClick={() => handleDocumentTypeChange("nfe")}
                    >
                      <p>NFe's</p>
                    </div>
                    <div
                      className={`btn cte ${
                        documentType === "cte" ? "active" : ""
                      }`}
                      onClick={() => handleDocumentTypeChange("cte")}
                    >
                      <p>CTe's</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="side">
                <div className="search">
                  <div className="btn" onClick={handleSearchSubmit}>
                    <IoSearchOutline />
                  </div>
                  <input
                    value={localSearchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Buscar por NFe"
                  />
                </div>
              </div>
            </div>
            <div className="table">
              <div className="head">
                <div className="colum check">
                  <div className="thumb white"></div>
                </div>
                <div className="colum data">
                  <CiCalendar />
                  <p>Data</p>
                </div>
                <div className="colum chave">
                  <IoCodeSlashOutline />
                  <p>Chave</p>
                </div>
                <div className="colum status">
                  <IoCodeSlashOutline />
                  <p>Status</p>
                </div>
                <div className="colum cnpj">
                  <IoCodeSlashOutline />
                  <p>CNPJ</p>
                </div>
              </div>
              <div
                className="display"
                ref={scrollContainerRef} // Ref para o container de scroll
              >
                {loading
                  ? Array.from({ length: 20 }).map((_, index) => (
                      <DocumentLoader key={index} />
                    ))
                  : documents.map((doc, index) => (
                      <div
                        className="items"
                        key={doc._id || index}
                        ref={
                          index === documents.length - 1
                            ? lastDocumentElementRef
                            : null
                        }
                      >
                        <div className="item check">
                          <div
                            className="thumb"
                            style={{
                              backgroundColor: doc._source.check
                                ? "#07bc0c"
                                : "",
                            }}
                          >
                            {doc._source.check ? (
                              <IoCheckmark style={{ color: "white" }} />
                            ) : null}
                          </div>
                        </div>
                        <div className="item data">
                          <p>
                            {new Date(doc._source.dhEvento)
                              .toLocaleString("pt-BR")
                              .replace(",", " - ")}
                          </p>
                        </div>
                        <div className="item chave">
                          <p>{doc._source.nfe}</p>
                        </div>
                        <div className="item status">
                          <p>{doc._source.status}</p>
                        </div>
                        <div className="item cnpj">
                          <p>{doc._source.cnpj}</p>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </Relatorio>
      </ReportDiv>
      <Mensal />
    </Container>
  );
}
