import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const ModalContent = styled.div`
  background: #05070a;
  padding: 20px;
  border-radius: 21px;
  border: 0.1px solid #505153;
  width: 400px;
  overflow-y: auto;
  max-height: 80vh;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: #8f150a;
  }
`;

export const DropzoneContainer = styled.div`
  border: 1.5px dashed #505153;
  opacity: 0.5;

  padding: 50px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Alinha verticalmente no centro */
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  width: ${(props) => (props.fullSize ? "74%" : "74%")}; /* Largura ajustável */
  height: ${(props) =>
    props.fullSize ? "300px" : "60px"}; /* Altura ajustável */
`;

export const UploadIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Tamanho do círculo */
  height: 50px; /* Tamanho do círculo */
  background-color: none; /* Cor de fundo */
  border-radius: 50%; /* Faz o círculo */
  font-size: 24px; /* Tamanho do símbolo de + */
  color: #1e1f22; /* Cor do símbolo */
  border: 2px solid #1e1f22; /* Borda */
  margin-right: 20px; /* Espaçamento entre o ícone e os textos */
  cursor: pointer;

  &:hover {
    background-color: none; /* Cor ao passar o mouse */
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column; /* Coloca os textos um embaixo do outro */
  text-align: left;
`;

export const Message = styled.p`
  color: #bbb;
  font-size: 21px;
  margin: 0; /* Remove margem para que não afete o layout */
`;

export const SubMessage = styled.p`
  color: #67686a;
  font-size: 13px;
  margin-top: 5px; /* Espaçamento entre o Message e o SubMessage */
`;

export const FileList = styled.ul`
  list-style: none;
  background: #0d0d11; /* Fundo mais escuro para o FileList */
  padding: -1px;
  margin-left: -20px; /* Remove padding esquerdo */
  margin-right: -28px;
  border: 0.1px solid #252528;
  height: 250px;
  overflow-y: auto; /* Permite a rolagem vertical */
`;

export const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #07090c;
  padding: 10px;
  margin-bottom: 0px;
  border: 0.1px solid #252528;
  color: #fff;
  font-size: 0.8rem;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #ff5555;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    color: #8f150a;
  }
`;

export const SendButton = styled.button`
  background: none;
  border: none;
  color: #4eb959;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: #12801d;
  }
`;
