import React from "react";
import { Container, Side, Card } from "./styles";

import DownloadCard from "./components/scripts/components/download";

import ScriptsCard from "./components/scripts";

import TaskCard from "./components/historico";
import WorkerCard from "./components/worker";
import NewNFeCard from "./components/movimentacao";
import UserCard from "./components/usuarios";
import DropBox from "./components/worker/components/dropbox";

export default function Upload() {
  return (
    <Container>

      <Side>
        <ScriptsCard />
        <DownloadCard />
      </Side>
      <Side>
        <TaskCard />
      </Side>
      <Side>
        <WorkerCard />
      </Side>
      <Side>
        <NewNFeCard />
      </Side>
      <Side>
        <UserCard />
      </Side>
    </Container>
  );
}
