import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5em);
  margin-top: 2em;
  display: flex;

  p {
    font-family: "Poppins";
  }
`;

export const Menu = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  background-color: rgba(5, 7, 10);

  .btn {
    width: max-content;
    height: 100%;
    height: 2em;
    border-bottom: 4px solid rgb(130, 185, 42, 0.5);
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.03);
    opacity: 0.6;
    border-right: 1px solid ${({ theme }) => theme.card};
    padding-right: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-bottom: 3px;
    padding-top: 3px;
    cursor: pointer;

    &.speds {
      border-bottom: 4px solid rgb(0, 100, 200, 0.5);
    }

    &:hover,
    &.ativ {
      opacity: 0.9;
    }
    p {
      font-size: 0.75em;
    }

    svg {
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.4em;
      padding-right: 10px;
    }
  }
`;

export const Cards = styled.div`
  width: 100%;
  min-height: 5em;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.contrast};
  align-items: center;
  justify-content: space-around;
  transform: translateY(0.5em);
`;

export const Card = styled.div`
  width: calc(100% / 4.8);
  height: 4em;
  display: flex;
  background-color: ${({ theme }) => theme.custom2};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.border5};
  flex-direction: column;

  .header {
    width: 100%;
    height: 1.5em;
    border-bottom: 1px solid ${({ theme }) => theme.card};
    display: flex;
    align-items: center;

    p {
      font-size: 0.75em;
      margin-left: 10px;
      opacity: 0.5;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1.5em);
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: 500;
    opacity: 0.7;
  }
`;

export const ReportDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3em;
`;

export const Relatorio = styled.div`
  width: 100%;
  min-height: calc(100vh - 13em);
  display: flex;
  align-items: center;
  justify-content: center;

  .block {
    width: 97%;
    height: 95%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.body2};
    border: 1px solid ${({ theme }) => theme.border5};
    margin-right: 5px;
    border-radius: 5px;

    .data {
      min-width: 12em !important;
    }

    .chave {
      min-width: 30em !important;
    }

    .status {
      min-width: 8em !important;
    }

    .cnpj {
      min-width: 20em !important;
    }

    .check {
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;

      .thumb {
        width: 0.5em;
        height: 0.5em;
        border-radius: 100%;
        border: 1px solid ${({ theme }) => theme.border};
        background-color: rgba(0, 0, 0, 0.05);

        &.white {
          background-color: transparent;
        }
      }
    }

    .head {
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.card2};
      justify-content: space-between;
      background-color: ${({ theme }) => theme.custom4};
      .side {
        width: max-content;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .btns {
          width: max-content;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          padding-bottom: 2px;

          .btn {
            display: flex;
            height: 100%;
            align-items: center;
            padding: 0 25px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background-color: ${({ theme }) => theme.custom3};
            border-bottom: 4px solid #3498db;
            opacity: 0.5;
            cursor: pointer;
            transition: all 0.1s;
            &:hover,
            &.ativ {
              opacity: 1;
            }

            &.cancel {
              border-bottom: 4px solid rgba(200, 0, 100);
            }

            &.auth {
              border-bottom: 4px solid rgba(0, 160, 100);
            }

            p {
              font-size: 0.9em;
            }
          }
        }

        .options {
          width: max-content;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .btns {
            width: max-content;
            height: 1.6em;
            display: flex;

            padding-bottom: 0px;

            .btn {
              cursor: pointer;
              height: 100%;
              opacity: 0.7;
              display: flex;
              align-items: center;
              transition: all 0.2s;
              border-bottom: 4px solid transparent; /* Default color */

              p {
                opacity: 0.8;
              }

              &:hover,
              &.active {
                opacity: 1;
              }

              &.all.active {
                border-bottom-color: rgba(0, 100, 200); /* Color for "Todas" */
              }

              &.nfe.active {
                border-bottom-color: rgba(0, 200, 100); /* Color for "NFe's" */
              }

              &.cte.active {
                border-bottom-color: rgba(200, 100, 0); /* Color for "CTe's" */
              }
            }
          }
        }

        .search {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          .btn {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.6;
            transform: translateX(25px);
            font-size: 0.8em;
          }

          input {
            background-color: ${({ theme }) => theme.card3};
            margin-right: 10px;
            width: 20em;
            color: white;
          }
        }
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 3em);
      display: flex;
      flex-direction: column;

      .head {
        width: 100%;
        height: 1.7em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: ${({ theme }) => theme.card4};
        border-bottom: 1px solid ${({ theme }) => theme.border};

        p {
          font-size: 0.8em;
        }

        .colum {
          width: max-content;
          height: 100%;
          display: flex;
          align-items: center;
          min-width: 3em;

          p {
            font-size: 0.75;
            opacity: 0.7;
          }

          svg {
            margin: 0 5px;
            margin-left: 10px;
            opacity: 0.7;
          }
        }
      }

      .display {
        width: 120%;
        height: calc(100% - 1.7em);
        background-color: ${({ theme }) => theme.load};
        overflow-y: scroll;
        margin-bottom: 20px;

        .items {
          width: 100%;
          min-height: 4em;
          display: flex;
          align-items: center;
          background-color: ${({ theme }) => theme.card};
          margin: 1px 0;
          border-radius: 5px;
          opacity: 0.9;
          :last-child {
            min-height: 5px; /* Ajuste o valor conforme necessário */
          }
          .item {
            height: 100%;
            display: flex;
            align-items: center;

            p {
              font-size: 0.9em;
              margin-left: 8px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;
export const Thumb = styled.div`
  width: 0.5em;
  height: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.border2};
  background-color: ${({ check, theme }) => (check ? "green" : theme.card)};
  svg {
    font-size: 1em;
    color: ${({ check }) => (check ? "white" : "inherit")};
  }
`;
