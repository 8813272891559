import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Container = styled.div`
  width: max-content;
  width: 22em;
  height: 25em;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  border-radius: 10px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  width: 100%;
  height: 5.5em;

  .top-header {
    width: 100%;
    height: 1.5em;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Torna o contêiner pai posicionado de forma relativa */

    p {
      font-size: 0.6em;
      opacity: 0.6;
    }

    .close {
      width: 1.5em;
      height: 100%;
      position: absolute; /* Posiciona o botão dentro do contêiner pai */
      top: 0; /* Alinha o botão no topo */
      right: 0; /* Alinha o botão no lado direito */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
      svg {
        font-size: 0.8em;
        opacity: 0.7;
        transition: opacity 0.2s ease;
        color: ${({ theme }) => theme.text};
      }
    }
  }

  .project {
    width: 100%;
    height: calc(4em - 2px);
    border-bottom: 1px solid ${({ theme }) => theme.border5} !important;
    display: flex;

    .thumb {
      width: 4em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border5};

      .circle {
        width: 60%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid ${({ theme }) => theme.border5};

        p {
          color: ${({ theme }) => theme.text};
        }
      }
    }

    .info {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;

      .info-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          height: 25%;
          margin-left: 15px;
          color: ${({ theme }) => theme.text};
          font-size: 0.7em;
          display: flex;
          align-items: center;
          opacity: 0.6;

          &:last-child {
            font-size: 0.6em;
          }
        }
      }
    }
  }
`;


export const Content = styled.div`
  width: 100%;
  height: calc(100% - 10.5em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .item {
    width: 90%;
    margin: 0.7em 0;
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0.5em;
      font-size: 0.7em;
      opacity: 0.6;
      color: ${({ theme }) => theme.text};
    }

    input {
      width: 100%;
      padding: 0.5em;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.custom2};
      border: 1px solid ${({ theme }) => theme.border5};
      outline: none;
      box-shadow: none;
      color: ${({ theme }) => theme.text};
    }

    &.check {
      align-items: flex-start;

      .option {
        display: flex;
        align-items: center;

        .checkbox {
          display: none;
        }

        label {
          position: relative;
          padding-left: 1.5em;
          cursor: pointer;
          font-size: 0.7em;
          color: ${({ theme }) => theme.text};

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1em;
            height: 1em;
            border: 1px solid ${({ theme }) => theme.border5};
            background-color: ${({ theme }) => theme.custom2};
            border-radius: 3px;
          }

          /* Estilo para o estado marcado do checkbox */
          &::after {
            content: '';
            position: absolute;
            left: 0.25em;
            top: 0.1em;
            width: 0.5em;
            height: 0.8em;
            border: solid ${({ theme }) => theme.text};
            border-width: 0 0.15em 0.15em 0;
            transform: rotate(45deg);
            opacity: 0;
          }
        }

        /* Quando o checkbox está marcado, mostra o ícone de seleção */
        .checkbox:checked + label::after {
          opacity: 1;
        }
      }
    }
  }
`;



export const Footer = styled.div`
  width: 100%;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;

  .create-button {
    width: 4em;
    height: 1.5em;
    background-color: #13cb31;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    p {
      font-size: 0.7em;
      color: white;
    }
  }
`;
