import styled from "styled-components";


export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.body};

  
    color: ${({ theme }) => theme.text} !important;
  
`;


